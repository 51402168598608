<template>
  <section>
    <home-banners-page />
    <div class="v-container">
      <menu-filters-main-page />
      <menu-groups-page />
      <menu-related
        v-if="
          appConfig.VueSettingsPreRun.MainPageLastSeenEnable ||
          appConfig.VueSettingsPreRun.MainPagePopularEnable
        "
        on-main-page
      />
    </div>
    <div class="v-container">
      <lazy-page-restaurant-list
        v-if="appConfig.VueSettingsPreRun.MainPageRestaurantEnable"
        is-main-page
      />
    </div>
    <div
      v-if="
        appConfig.VueSettingsPreRun.MainPageAppLinkEnable ||
        appConfig.VueSettingsPreRun.MainPageSocialLinkEnable
      "
      class="v-container v-py-lg"
    >
      <div class="v-row">
        <lazy-home-suggest-apps-page
          v-if="appConfig.VueSettingsPreRun.MainPageAppLinkEnable"
        />
        <lazy-home-suggest-social />
      </div>
    </div>
    <lazy-menu-recommended />
  </section>
</template>

<script setup lang="ts">
const appConfig = useAppConfig()
</script>
